const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_HK8P3FVE9",
    "aws_user_pools_web_client_id": "4vem89b2iqnvmi8b1ts9notp4b",
    "aws_user_files_s3_bucket": "senso-static-files-us",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "oauth": {
        "domain": 'onenevada.auth.us-east-1.amazoncognito.com',
        "scope": ['email', 'openid', 'profile'],
        "redirectSignIn": 'https://onenevada.cucopilot.com/callback',
        "redirectSignOut": 'https://onenevada.cucopilot.com/logout',
        "responseType": 'code'
    }
};

export default awsmobile;