import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from "../../hooks/reduxHooks"; // Import useAppDispatch hook
import { setCurrentUser } from '../../state/features/user/userSlice'; // Import the action to set the current user
import { getUserDetails } from '../../services/api/userAPI'; // Import your getUserDetails function

const CallbackPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch(); // Use the dispatch function from the Redux hook

    useEffect(() => {
        const handleAuth = async () => {
            try {
                // Ensure the user is authenticated
                await Auth.currentAuthenticatedUser({ bypassCache: true });

                // Use the getUserDetails function to fetch user details
                const userDetails = await getUserDetails();
                
                // Dispatch an action to update the user state in the Redux store
                dispatch(setCurrentUser(userDetails));

                // Redirect to /fetch using navigate
                navigate('/fetch');
            } catch (error) {
                console.error("Error handling auth callback:", error);
                navigate('/login'); // Navigate to login on error
            }
        };
        
        handleAuth();
    }, [navigate, dispatch]);

    return (
        <div>
            Authenticating...
        </div>
    );
};

export default CallbackPage;
