import React, { useState } from 'react';
import styles from './login-page.module.css';
import { setAuthToken } from '../../services/auth-service';
import { createUser } from '../../services/api-service';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { useAppSelector } from "../../hooks/reduxHooks";

Auth.configure(awsconfig);

export const LoginPage = () => {
  const navigate = useNavigate();
  const lastModule = useAppSelector((state) => state.app.currentModule);
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmationCodeInput, setShowConfirmationCodeInput] = useState(false);
  const [showChangePasswordInput, setShowChangePasswordInput] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const location = useLocation();
  const logoUrl = process.env.REACT_APP_MAIN_LOGO || "https://public-styling-assets.s3.amazonaws.com/senso/Senso-Logo.png";
  const REACT_APP_AUTH_TITLE = process.env.REACT_APP_AUTH_TITLE || "Tools to Empower Your People";
  const REACT_APP_AUTH_SUBTITLE = process.env.REACT_APP_AUTH_SUBTITLE || "";
  const REACT_APP_AUTH_HIW_TITLE_1 = process.env.REACT_APP_AUTH_HIW_TITLE_1 || "Accelerated Hiring and Onboarding";
  const REACT_APP_AUTH_HIW_DESC_1 = process.env.REACT_APP_AUTH_HIW_DESC_1 || "It’s never been easier for new employees to get up to speed";
  const REACT_APP_AUTH_HIW_TITLE_2 = process.env.REACT_APP_AUTH_HIW_TITLE_2 || "Enhanced Employee Engagement";
  const REACT_APP_AUTH_HIW_DESC_2 = process.env.REACT_APP_AUTH_HIW_DESC_2 || "Give employees the information they need to grow";
  const REACT_APP_AUTH_HIW_TITLE_3 = process.env.REACT_APP_AUTH_HIW_TITLE_3 || "Proactive Performance Analysis";
  const REACT_APP_AUTH_HIW_DESC_3 = process.env.REACT_APP_AUTH_HIW_DESC_3 || "Enable your teams to see and track their performance";
  const FEDERATION = process.env.REACT_APP_SSO_LOGIN || "onenevada-azure-ad";

  console.log("------------------------------------------------------");
  console.log(FEDERATION);
  console.log("------------------------------------------------------");

  const handleSignInClick = async (event) => {
    event.preventDefault();

    try {
      const email = document.querySelector(`.${styles.authEmailInput}`).value;
      const password = document.querySelector(`.${styles.authPasswordInput}`).value;
      const signInResponse = await Auth.signIn(email, password);
      const signInUserSession = signInResponse.signInUserSession;
      const idToken = signInUserSession.idToken;
      const refreshToken = signInUserSession.refreshToken;
      const isEmailVerified = idToken.payload.email_verified;

      if (!isEmailVerified) {
        throw new Error('UserNotConfirmedException');
      } else if (signInResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setShowChangePasswordInput(true);
        setErrorMessage('');
      } else {
        const token = idToken.jwtToken;
        const expiry = idToken.getExpiration();
        setAuthToken(token, refreshToken.token, expiry);
        const currentPath = JSON.parse(sessionStorage.getItem('currentPath'));
        if (currentPath) {
          navigate(currentPath);
          sessionStorage.removeItem('currentPath');
        } else if (currentPath === "/login" || currentPath === "/signup" || currentPath === "/") {
          if (lastModule === 'echo') {
            navigate('/echo');
          } else {
            navigate('/fetch');
          }
        } else {
          if (lastModule === 'echo') {
            navigate('/echo');
          } else {
            navigate('/fetch');
          }
        }
        setErrorMessage('');
      }
    } catch (error) {
      if (error.code === 'UserNotConfirmedException') {
        setShowConfirmationCodeInput(true);
        setErrorMessage('');
      } else {
        setErrorMessage('Incorrect email or password');
      }
    }
  };

  const handleConfirmationCodeSubmit = async (event) => {
    event.preventDefault();

    try {
      const email = document.querySelector(`.${styles.authEmailInput}`).value;
      const password = document.querySelector(`.${styles.authPasswordInput}`).value;
      const code = confirmationCode.trim();

      await Auth.confirmSignUp(email, code);

      const signInResponse = await Auth.signIn(email, password);
      createUser(signInResponse.username, email, signInResponse.attributes.given_name, signInResponse.attributes.family_name);
      const token = signInResponse.signInUserSession.idToken.jwtToken;
      const expiry = signInResponse.signInUserSession.idToken.getExpiration();
      const refreshToken = signInResponse.signInUserSession.refreshToken.token;
      setAuthToken(token, refreshToken, expiry);
      const currentPath = JSON.parse(sessionStorage.getItem('currentPath'));
      if (currentPath) {
        navigate(currentPath);
        sessionStorage.removeItem('currentPath');
      } else if (currentPath === "/login" || currentPath === "/signup" || currentPath === "/") {
        if (lastModule === 'echo') {
          navigate('/echo');
        } else {
          navigate('/fetch');
        }
      } else {
        if (lastModule === 'echo') {
          navigate('/echo');
        } else {
          navigate('/fetch');
        }
      }

      setShowConfirmationCodeInput(false);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Invalid confirmation code');
    }
  };

  const handleChangePasswordSubmit = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setErrorMessage('New passwords do not match');
      return;
    }

    try {
      const email = document.querySelector(`.${styles.authEmailInput}`).value;

      await Auth.completeNewPassword(
        email,
        newPassword,
        signInResponse.challengeParam.requiredAttributes
      );

      const signInResponse = await Auth.signIn(email, newPassword);

      const token = signInResponse.signInUserSession.idToken.jwtToken;
      const expiry = signInResponse.signInUserSession.idToken.getExpiration();
      const refreshToken = signInResponse.signInUserSession.refreshToken.token;
      setAuthToken(token, refreshToken, expiry);
      const currentPath = JSON.parse(sessionStorage.getItem('currentPath'));
      if (currentPath) {
        navigate(currentPath);
        sessionStorage.removeItem('currentPath');
      } else if (currentPath === "/login" || currentPath === "/signup" || currentPath === "/") {
        if (lastModule === 'echo') {
          navigate('/echo');
        } else {
          navigate('/fetch');
        }
      } else {
        if (lastModule === 'echo') {
          navigate('/echo');
        } else {
          navigate('/fetch');
        }
      }

      setShowChangePasswordInput(false);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Error occurred while changing password');
    }
  };

  const handleResendConfirmationCode = async () => {
    try {
      const email = document.querySelector(`.${styles.authEmailInput}`).value;

      await Auth.resendSignUp(email);

      setErrorMessage('Confirmation code has been resent');
    } catch (error) {
      setErrorMessage('Error occurred while resending confirmation code');
    }
  };

  const logoStyle = {
    backgroundImage: `url(${logoUrl})`,
  };

  const handleFedLogin = async () => {
    try {
      await Auth.federatedSignIn();
    } catch (err) {
      console.error('Error initiating federated sign-in:', err);
    }
  };

  return (
    <div className={styles.authContainer}>
      <header className={styles.authHeader}>
        <div className={styles.authHeaderInner}>
          <div className={styles.logoContainer} style={logoStyle}></div>
          {/*<button className={styles.authButton} onClick={() => navigate('/signup')}>
            Sign Up
  </button>*/}
        </div>
      </header>

      <div className={styles.authLeft}>
        <div className={styles.authTextContainer}>
          <h1>
            <span className={styles.authGreenText}>AI-Powered</span> {REACT_APP_AUTH_TITLE}
          </h1>
          <div className={styles.authTextGetAccess}>
            <h2>{REACT_APP_AUTH_SUBTITLE}</h2>
            <p>Sign in to access your account</p>
          </div>

          <div className={`${styles.authInputContainer} ${styles.authInputContainerSignIn}`}>
            {(FEDERATION === null) && (<form onSubmit={handleSignInClick}>
              {errorMessage && (
                <div className={styles.authErrorMessage}>{errorMessage}</div>
              )}
              <div className={`${styles.authInputRow} ${styles.authInputRowSignIn}`}>
                <input
                  type="text"
                  placeholder="Email"
                  className={`${styles.authInput} ${styles.authEmailInput}`}
                />
              </div>
              <div className={`${styles.authInputRow} ${styles.authInputRowSignIn}`}>
                <input
                  type="password"
                  placeholder="Password"
                  className={`${styles.authInput} ${styles.authPasswordInput}`}
                />
              </div>
              {showConfirmationCodeInput && (
                <>
                  <div className={`${styles.authInputRow} ${styles.authInputRowSignIn}`}>
                    <input
                      type="text"
                      placeholder="Confirmation Code"
                      className={`${styles.authInput} ${styles.authConfirmationCodeInput}`}
                      value={confirmationCode}
                      onChange={(e) => setConfirmationCode(e.target.value)}
                    />
                  </div>
                  <div className={styles.authInputRow}>
                    <button
                      className={styles.authButtonWaitlist}
                      onClick={handleConfirmationCodeSubmit}
                      type="button"
                    >
                      Submit Confirmation Code
                    </button>
                    <button
                      className={styles.authButtonWaitlist}
                      onClick={handleResendConfirmationCode}
                      type="button"
                    >
                      Resend Confirmation Code
                    </button>
                  </div>
                </>
              )}
              {showChangePasswordInput && (
                <>
                  <div className={`${styles.authInputRow} ${styles.authInputRowSignIn}`}>
                    <input
                      type="password"
                      placeholder="New Password"
                      className={`${styles.authInput} ${styles.authNewPasswordInput}`}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <div className={`${styles.authInputRow} ${styles.authInputRowSignIn}`}>
                    <input
                      type="password"
                      placeholder="Confirm New Password"
                      className={`${styles.authInput} ${styles.authConfirmNewPasswordInput}`}
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                  </div>
                  <div className={styles.authInputRow}>
                    <button
                      className={styles.authButtonWaitlist}
                      onClick={handleChangePasswordSubmit}
                      type="button"
                    >
                      Change Password
                    </button>
                  </div>
                </>
              )}
              {!showConfirmationCodeInput && !showChangePasswordInput && (
                <>
                  <div className={styles.authInputRow}>
                    <button className={styles.authButtonWaitlist} type="submit">
                      Sign In
                    </button>
                  </div>
                  <div className={styles.authInputRow}>
                    <Link className={styles.forgotPassword} to="/password_reset">Forgot Password?</Link>
                  </div>
                </>
              )}
            </form>)}
            {(FEDERATION !== null) && (
              <button className={styles.authButtonWaitlist} onClick={handleFedLogin} >Sign In</button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.authRight}>
        <div className={styles.authHowItWorksWrapper}>
          <div className={styles.authHowItWorks}>
            <div className={styles.authHowItWorksRow}>
              <div className={styles.icon1}></div>
              <div className={styles.authHowItWorksText}>
                <h3 className={styles.authHowItWorksTitle}>{REACT_APP_AUTH_HIW_TITLE_1}</h3>
                <p className={styles.authHowItWorksDescription}>{REACT_APP_AUTH_HIW_DESC_1}</p>
              </div>
            </div>
            <div className={styles.authHowItWorksRow}>
              <div className={styles.icon2}></div>
              <div className={styles.authHowItWorksText}>
                <h3 className={styles.authHowItWorksTitle}>{REACT_APP_AUTH_HIW_TITLE_2}</h3>
                <p className={styles.authHowItWorksDescription}>{REACT_APP_AUTH_HIW_DESC_2}</p>
              </div>
            </div>
            <div className={styles.authHowItWorksRow}>
              <div className={styles.icon3}></div>
              <div className={styles.authHowItWorksText}>
                <h3 className={styles.authHowItWorksTitle}>{REACT_APP_AUTH_HIW_TITLE_3}</h3>
                <p className={styles.authHowItWorksDescription}>{REACT_APP_AUTH_HIW_DESC_3}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
